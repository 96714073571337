
import './App.css';
import { useState } from 'react';
// import Run from 'shruggr-lib/browser-run';
import { Blockchain } from 'shruggr-lib/blockchain';
import { Cache } from 'shruggr-lib/cache';
import { NullPurse } from 'shruggr-lib/null-purse'

const API_KEY = 'TEST1234';
const { Run } = window;

function App() {
  const [jig, setJig] = useState(null);
  const localCache = new Run.plugins.LocalCache();
  const run = new Run({
    apiKey: API_KEY,
    blockchain: new Blockchain(API_KEY, localCache, true),
    cache: new Cache(API_KEY, localCache),
    purse: NullPurse,
    owner: window.owner,
    trust: '*',
  })

  const mint = async () => {
    // await initialized;
    class TestJig extends window.Jig {
      init(owner) {
        this.owner = owner;
        this.satoshis = 1;
      }
    }

    await run.deploy(TestJig);
    await run.sync();

    const inst = new TestJig(run.owner);
    await inst.sync();
    setJig(inst);

  }

  return (
    <div className="wrapper">
      <div className='container header'>
        <div className='row'>
          <div className='col title'>
            <h1>¯\_(ツ)_/¯</h1>
            <h2>shruggr.cloud</h2>
          </div>
          <div className="col">
            { /*{welcome} */}
          </div>
        </div>
      </div>
      {/* {signin} */}
      <div>
        <button onClick={mint}>Mint</button>
        {jig?.location}
      </div>
    </div>
  );
}

export default App;