import { TXO } from './types';
import { Cache } from './cache';
import 'isomorphic-fetch';
import * as createError from "http-errors";

const API = 'https://shruggr.cloud/api';
export class Blockchain {
    network = 'main';
    constructor(private apiKey: string, private localCache: Cache, public useRelayX = false, public debug = false) { }

    async broadcast(rawtx: string): Promise<string> {
        console.log("Blockchain Broadcast", rawtx);
        if (this.debug) console.log('BROADCAST:', rawtx);
        if (this.useRelayX) {
            const relayone = (window as any)?.relayone;
            const resp = await relayone.send(rawtx);
            console.log('Relay Response', resp);
            rawtx = resp.rawTx
        }
        const resp = await fetch(`${API}/broadcast`, {
            method: 'POST',
            headers: {
                authorization: this.apiKey,
                "content-type": "application/json"
            },
            body: JSON.stringify({ rawtx: Buffer.from(rawtx, 'hex').toString('base64') }),
        });

        if (!resp.ok) {
            throw createError(resp.status, await resp.text())
        }
        const txid = await resp.text();
        if (this.debug) console.log('RESP:', txid);
        return txid;
    }

    async fetch(txid: string): Promise<string> {
        if (this.debug) console.log('FETCH:', txid);
        let rawtx = '';
        let cacheKey = `tx:${txid}`;
        if (this.localCache) {
            rawtx = await this.localCache.get(cacheKey)
        }
        if (!rawtx) {
            const resp = await fetch(`${API}/tx/${txid}`, {
                method: 'GET',
                headers: {
                    authorization: this.apiKey
                }
            });

            if (!resp.ok) {
                throw createError(resp.status, await resp.text())
            }
            rawtx = Buffer.from(await resp.text(), 'base64').toString('hex');
            await this.localCache.set(cacheKey, rawtx);
        }
        return rawtx;
    }

    async utxos(script: string): Promise<TXO[]> {
        if (this.debug) console.log('UTXOS:', script);
        const resp = await fetch(`${API}/utxos/${script}`, {
            method: 'GET',
            headers: {
                authorization: this.apiKey
            }
        });

        if (!resp.ok) {
            throw createError(resp.status, await resp.text())
        }
        const utxos = await resp.json();
        return utxos;
    }

    async spends(txid: string, vout: number) {
        if (this.debug) console.log('SPENDS:', txid, vout);
        let spendTxid = '';
        let cacheKey = `spend:${txid}:${vout}`;
        if (this.localCache) {
            spendTxid = await this.localCache.get(cacheKey)
        }
        if (!spendTxid) {
            const resp = await fetch(`${API}/spends/${txid}/${vout}`, {
                method: 'GET',
                headers: {
                    authorization: this.apiKey
                }
            });

            if (!resp.ok) {
                throw createError(resp.status, await resp.text())
            }
            spendTxid = await resp.text();
        }
        return spendTxid;
    }

    time() { }

    async get(key: string) {
        if (this.debug) console.log('GET:', key)
        if (!key.startsWith('jig:')) return
        const resp = await fetch(`${API}/state/${encodeURIComponent(key)}`, {
            method: 'GET',
            headers: {
                authorization: this.apiKey
            }
        })

        let val: any
        if (resp.ok) {
            val = await resp.json()
        } else if (resp.status !== 404) {
            throw createError(resp.status, await resp.text())
        }

        return val
    }

    set() { }
}